<template>
  <div class="slider-area fix">
    <div class="slider-active swiper-container">
      <swiper
        ref="mySwiper"
        :modules="modules"
        :effect="'fade'"
        :loop="true"
        :autoplay="{ delay: 5000 }"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
      >
        <swiper-slide v-for="item in sliders" :key="item.id">
          <div class="slider-list swiper-slide d-flex align-items-center pt-70">
            <div class="slider-shape"></div>
            <div class="slider-shape-line"></div>
            <div class="slider-shape-line2"></div>
            <div
              class="slider-bg img-zoom"
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../assets/img/slider/' + item.image) +
                  ')',
              }"
            ></div>
            <div class="container">
              <div class="row">
                <div class="col-12 position-relative">
                  <div class="slider-circle-shape"></div>
                  <div
                    class="slider-text z-index text-center text-version"
                    style="overflow: hidden"
                  >
                    <!-- <span class="slider-sub-title">Καλωσηρθατε στην <div class="larger-title">AiPath</div></span> -->
                    <h2 class="slider-title pre-formatted">
                      {{ item.title }}
                    </h2>
                    <div class="slide-btn mt-30">
                      <router-link to="/about" class="tp-btn">{{
                        item.btnText
                      }}</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <!-- If we need navigation buttons -->
      <div class="swiper-button-prev sp-arrow">
        <i class="far fa-angle-left"></i>
      </div>
      <div class="swiper-button-next sp-arrow">
        <i class="far fa-angle-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, EffectFade, Autoplay } from "swiper";
export default {
  name: "HeroSlider",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      sliders: [
        {
          id: "1",
          image: "SliderGraphicsCard.webp",
          // title: "Web Design \n Agency",
          title: "Στόχος μας: \nΗ ανάπτυξη λύσεων τεχνητής νοημοσύνης.",
          // btnText:'Discover More',
          btnText: "Η ΕΤΑΙΡΕΙΑ",
        },
        {
          id: "2",
          image: "SliderServer.webp",
          // title: "Web Design \n Agency",
          title:
            "Eπενδύσαμε στις καλύτερες δυνατές υποδομές,\n αποκτώντας την περιζήτητη κάρτα γραφικών \nNVIDIA H100 80GB!",
          // btnText:'Discover More',
          btnText: "Η ΕΤΑΙΡΕΙΑ",
        },
        {
          id: "3",
          image: "slider3.webp",
          // title: "Web Design \n Agency",
          title:
            "Μια εταιρεία τεχνολογίας \n δίπλα  σε κάθε ανάγκη κάθε επιχείρησης",
          // btnText:'Discover More',
          btnText: "Η ΕΤΑΙΡΕΙΑ",
        },
        {
          id: "4",
          image: "slider4.webp",
          // title: 'Smart Think \n So Easy.',
          title:
            "Την πρωτοπόρο εταιρεία \nστην εφαρμογή της τεχνητής νοημοσύνης",
          // btnText:'Discover More',
          btnText: "Η ΕΤΑΙΡΕΙΑ",
        },
      ],
    };
  },

  setup() {
    return {
      modules: [Navigation, EffectFade, Autoplay],
    };
  },
};
</script>

<style>
.larger-title {
  font-size: 35px;
}
</style>
