<template>
  <div class="about-area pt-120 pb-90">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6">
          <div class="ab-wrapper pos-rel">
            <div class="ab-since">
              <h4>
                Εδώ <br />
                για Εσάς
              </h4>
            </div>
            <div class="row gx-2 mb-30">
              <div class="col-6">
                <div class="ab-img img-filter">
                  <img src="../../assets/img/about/abs-sm3.webp" alt="People working in the office and smiling" />
                </div>
              </div>
              <div class="col-6">
                <div class="ab-img img-filter">
                  <img src="../../assets/img/about/abs-sm1.webp" alt="Black person in the office smiling" />
                </div>
              </div>
              <div class="col-6">
                <div class="ab-img img-filter">
                  <img src="../../assets/img/about/abs-sm2.webp" alt="Black person in the office smiling" />
                </div>
              </div>
              <div class="col-6">
                <div class="ab-img img-filter">
                  <img src="../../assets/img/about/abs-sm4.webp" alt="People working in the office and smiling" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6">
          <div class="ab-box pl-50 mb-30">
            <div class="sec-wrapper">
              <!-- <h5>About Us</h5> -->
              <h2 class="section-title">
                Είμαστε <span>ο σύμμαχός σας</span> σε έναν κόσμο που
                εξελίσσεται γρήγορα
              </h2>
            </div>
            <div class="ab-item-box mt-40 mb-30">
              <div class="ab-items fix mb-20">
                <div class="ab-icon f-left">
                  <i class="flaticon-honesty"></i>
                </div>
                <div class="ab-item-text fix">
                  <h3 class="ab-item-title">Supporting Startups</h3>
                  <p>
                    Για νέες επιχειρήσεις που κάνουν τα πρώτα τους βήματα στην
                    ψηφιοποίηση, η AiPath είναι πάντα δίπλα τους, παρέχοντας
                    υπηρεσίες όπως Ψηφιακό Μάρκετινγκ, Σχεδιασμός (UI/UX),
                    Υπηρεσίες Cloud, Επιχειρηματική Στρατηγική, Ανάπτυξη
                    Εταιρικής Ταυτότητας και Ψηφιακές Υπηρεσίες Συμβουλευτικής.
                  </p>
                </div>
              </div>
              <div class="ab-items fix mb-20">
                <div class="ab-icon f-left">
                  <i class="flaticon-trustworthy"></i>
                </div>
                <div class="ab-item-text fix">
                  <h3 class="ab-item-title">Our Commitment</h3>
                  <p>
                    Ο τελικός μας σκοπός είναι οι εφαρμογές που σχεδιάζουμε να
                    ενσωματωθούν στην απαιτητική νέα εποχή της τεχνητής
                    νοημοσύνης. Αναπτύσσουμε εργαλεία, όπως μοντέλα πρόβλεψης,
                    που είναι ικανά να δημιουργήσουν πληροφορίες σχετικά με το
                    εάν ένας πελάτης θα κάνει παραγγελία και, αν ναι, πότε. Αυτό
                    εξασφαλίζει ότι οι εταιρείες που εμπιστεύονται τις υπηρεσίες
                    μας είναι πάντα ένα βήμα μπροστά από τους ανταγωνιστές τους.
                  </p>
                </div>
              </div>
            </div>
            <div v-if="checkRoute" class="ab-btn">
              <router-link to="/why-aipath" class="tp-btn">Πληροφοριες</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  computed: {
    checkRoute() {
      return !(this.$route.path === "/about");
    },
  },
};
</script>
