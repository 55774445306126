<template>
    <div class="main-services pt-120 pb-90 grey-bg" :style="{backgroundImage:'url(' + require('../../assets/img/pattern/pt1.webp') +')'}">
           <div class="container">
              <div class="row mb-60">
                 <div class="col-12">
                    <div class="sec-wrapper text-center">
                       <h5 >AiPath</h5>
                       <h2 class="section-title">Our Path</h2>
                    </div>
                 </div>
              </div>
              <div class="row text-center">
                 <div v-for="service in servicesData" :key="service.id" class="col-xl-4 col-lg-4 col-md-6 mb-30">
                    <div class="mfbox mfbox-white">
                       <div class="mf-shape"></div>
                       <div class="mfbox__icon mb-15">
                          <i :class="service.icon"></i>
                       </div>
                       <div class="mfbox__text">
                          <h3 class="mf-title" v-html="service.title"></h3>
                          <p>{{ service.text }}</p>   
                       </div>
                       <!-- <div class="mf-btn">
                          <router-link class="squire-btn" to="/services-details">
                             <i class="fal fa-angle-right"></i>
                          </router-link>
                       </div> -->
                    </div>
                 </div>
                 <PaginationArea path="/services" title="Περισσότερα"/>
              </div>
           </div>
        </div>
</template>

<script>
import PaginationArea from '../PortfolioDetails/PaginationArea.vue';
export default {
   name:'ServicesArea',
   components:{
    PaginationArea,
   },
   data () {
     return {
        servicesData:[
           {
              id:'1',
              icon:'flaticon-web-optimization',
              title:'AI Solutions',
              text:'Δημιουργούμε custom εφαρμογές οι οποίες περιλαμβάνουν πρωτοποριακά μοντέλα τεχνητής νοημοσύνης που εκπαιδεύουμε βάσει των αναγκών σας. Είτε πρόκειται για Desktop, web ή mobile εφαρμογές, η ομάδα μας συνδυάζει την τεχνολογία AI με εκπληκτικό design για να σας παρέχει το καλύτερο αποτέλεσμα.'
           },
           {
              id:'2',
              icon:'flaticon-copywriter',
              title:'Custom Desktop Applications',
              text:'Στην εταιρεία μας αναπτύσσουμε εξατομικευμένες εφαρμογές για τον υπολογιστή σας, προσφέροντας λύσεις που προσαρμόζονται ακριβώς στις ανάγκες σας.'
           },
           {
              id:'3',
              icon:'flaticon-graphic-design',
              title:'StartUp',
              text:'Για νέες επιχειρήσεις που κάνουν τα πρώτα τους βήματα στην ψηφιοποίηση, η AiPath είναι πάντα δίπλα τους, παρέχοντας υπηρεσίες όπως Ψηφιακό Μάρκετινγκ, Σχεδιασμός (UI/UX), Υπηρεσίες Cloud, Επιχειρηματική Στρατηγική, Ανάπτυξη Εταιρικής Ταυτότητας και Ψηφιακές Υπηρεσίες Συμβουλευτικής.'
           },
        ]
     }
  }
}
</script>