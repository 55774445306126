<template>
  <div class="sec-wrapper" style="background-color: #f5f5f5">
    <div class="container-fluid">
      <template v-if="useAlternativeTemplate">
        <div class="row gx-0">
          <div
            style="background-color: #f5f5f5"
            class="col-12 col-lg-12 col-xl-3 d-flex flex-column align-items-center justify-content-center"
          >
            <div
              class="d-flex flex-column align-items-center justify-content-center p-2"
            >
              <h5 class="mt-4">AiPath</h5>
              <h3 class="section-title mb-4 text-center">Tο επόμενο βήμα</h3>
              <p class="text-center">
                Ανακαλύψτε την Ψηφιακή Επανάσταση με την AiPath: <br />
                Μεταμορφώνοντας τα Δεδομένα σας σε Μετρήσιμο Κέρδος με την χρήση
                της Τεχνητής Νοημοσύνης
              </p>
            </div>
          </div>
          <div
            style="background-color: #f5f5f5"
            class="col-12 col-lg-12 col-xl-9 p-0"
          >
            <video
              class="img-fluid mt-2"
              ref="autoplayVideo"
              src="../../assets/video/AiPath.mp4"
              muted
              loop
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </template>
      <template v-if="!useAlternativeTemplate">
        <div class="row d-flex row-flex flex-column mb-4">
          <div
            style="background-color: #f5f5f5"
            class="d-flex flex-column align-items-center justify-content-center small-column"
          >
            <div
              class="small-content d-flex flex-column align-items-center justify-content-center"
            >
              <h5 class="mt-4">AiPath</h5>
              <h3 class="section-title mb-4">Tο επόμενο βήμα</h3>
              <p v-if="useAlternativeTemplate" class="text-center">
                Ανακαλύψτε την Ψηφιακή Επανάσταση με την AiPath: <br />
                Μεταμορφώνοντας τα Δεδομένα σας σε Μετρήσιμο Κέρδος με την χρήση
                της Τεχνητής Νοημοσύνης
              </p>
            </div>
          </div>
          <div
            style="background-color: #f5f5f5"
            class="large-column d-flex justify-content-end"
          >
            <video
              class="w-100 mb-4 mt-4"
              ref="autoplayVideo"
              src="../../assets/video/AiPath.mp4"
              muted
              loop
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    useAlternativeTemplate: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$refs.autoplayVideo.play();
  },
};
</script>

<style scoped></style>
