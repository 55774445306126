export default {
  menu_greek: "Greek",
  menu_english: "English",
  //Home
  menu_Home: "Home",
  //services
  menu_services: "Services",
  submenu_title_Development: "Development",
  submenu_CustomDesktop: "Custom Desktop Apps",
  submenu_WebApplications: "Web Applications",
  submenu_MobileApplications: "Mobile Applications",
  submenu_ECommerce: "E-Commerce Solutions",
  submenu_ApiDevelopment: "Api Development",
  submenu_MachineLearning: "Machine Learning Solutions",
  //start ups
  menu_startups: "For Startups",
  submenu_DigitalConsulting: "Digital Consulting",
  submenu_DigitalMarketing: "Digital Marketing",
  submenu_BusinessStrategy: "Business Strategy",
  submenu_BrandDevelopment: "Brand Development",
  submenu_WebsiteDevelopment: "Website Development",
  submenu_DesignUIUX: "Design (UI/UX)",
  submenu_CloudSolutions: "Cloud Solutions",
  //about us
  menu_about: "About Us",
  submenu_WhoAreWe: "Who are we",
  submenu_WithAiPath: "With AiPath",
  submenu_WhyAiPath: "Why AiPath",
  submenu_Team: "Team",
  submenu_Careers: "Careers",
  submenu_Contact: "Contact",
  //News
  menu_news: "News",
  //Language
  menu_language: "Language",
  //Get Offer
  menu_getOffer: "Get Offer",
};
