<template>
  <details class="accordion">
    <summary class="accordion-header">
      <div class="accordion-header-content">
        <span class="accordion-header-content-label">{{ title }}</span>
      </div>
    </summary>
    <div class="accordion-content">
      <li v-for="link in links" :key="link.id"> 
        <router-link :to="link.path"><span>  </span>{{link.title}}</router-link>
      </li>
    </div>
  </details>
</template>

<script>
  export default{
    props:{
      title: String,
      links: Array
    }
  }
</script>

<style lang="scss">

.accordion {
  border-radius: 3px;
  background: white;
  color: black;
  overflow: hidden;
}

.accordion-header {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.accordion-header-content {
  padding: 10px;
  overflow: hidden;
  position: relative;
  z-index: 9;
}

.accordion-header-content-label {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 450;
  font-size: 20px;
  padding-left: 0.9rem;
  color: black;
}

.accordion-header-content-title {
  margin-top: 10px;
}

.accordion-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;
  overflow: hidden;
  // margin-left: 2rem;
}
.accordion-content li{
  margin: 0!important;
  padding:0!important;
}
.accordion-content li a{
  color: black!important;
  padding: 0!important;
}
.accordion-content li a:hover{
  color:#fd914f!important;
}
/* accordion open effect */

details summary {
  cursor: pointer;
  transition: all 225ms ease-out;
  position: relative;
}

details[open] summary {
  margin-bottom: 100px;
  overflow: hidden;
  position: relative;
  background: #ccc;
}

details[open] .accordion-content {
  padding: 10px;
  margin-top: -100px;
}

details[open] .accordion-header-content-label {
  color: black;
}

/* remove marker */

details > summary {
  list-style: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

/* custom marker */

summary::after {
  content: "+";
  position: absolute;
  top: 2px;
  right: 10px;
  font-size: 26px;
}

details[open] summary::after {
  content: "^";
  font-size: 36px;
}

</style>
