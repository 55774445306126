<template>
  <header>
    <div
      id="header-sticky"
      :class="`${
        isSticky
          ? 'header-area header-transparent header-pad header-border sticky'
          : 'header-area header-transparent header-pad header-border'
      }`"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-xl-2 col-lg-2 col-6">
            <div style="display: flex; align-items: center">
              <div class="logo logo-border">
                <a href="/" class="">
                  <img
                    class="logo-white"
                    :src="require('../../assets/img/logo/LogoPg-smaller.webp')"
                    alt="Aipath Compamy Logo"
                  />
                </a>
              </div>
              <a href="/" class="">
                <h1
                  class="header-title-custom"
                  style="color: white; margin: 0px"
                >
                  AiPath
                </h1>
              </a>
            </div>
          </div>
          <!-- <div class="col-xl-1 col-lg-1 col-3">
            <h1 style="color: white; padding: 0 1rem; margin: 0">AiPath</h1>
          </div> -->
          <div class="col-xl-9 col-lg-9 d-none d-lg-block">
            <div class="main-menu text-center">
              <nav id="mobile-menu">
                <ul>
                  <!-- <li>
                    <router-link to="/"> Home </router-link>
                  </li> -->
                  <li>
                    <router-link to="/services">{{
                      $t("menu_services")
                    }}</router-link>
                    <ul class="sub-menu">
                      <AccordionMenu
                        :title="this.$t('submenu_title_Development')"
                        :links="[
                          {
                            title: this.$t('submenu_CustomDesktop'),
                            path: '/custom-apps',
                            id: 1,
                          },
                          {
                            title: this.$t('submenu_WebApplications'),
                            path: '/web-apps',
                            id: 2,
                          },
                          {
                            title: this.$t('submenu_MobileApplications'),
                            path: '/mobile-apps',
                            id: 3,
                          },
                          {
                            title: this.$t('submenu_ECommerce'),
                            path: '/ecommerce',
                            id: 4,
                          },
                          {
                            title: this.$t('submenu_ApiDevelopment'),
                            path: '/api-dev',
                            id: 5,
                          },
                        ]"
                      />

                      <!-- <li>
                        <router-link to="/edu">Edu</router-link>
                      </li> -->
                      <li>
                        <router-link to="/machine-learning-solutions">{{
                          $t("submenu_MachineLearning")
                        }}</router-link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <router-link to="/services">{{
                      $t("menu_startups")
                    }}</router-link>
                    <ul class="sub-menu">
                      <li>
                        <router-link to="/cloud-solutions">{{
                          $t("submenu_CloudSolutions")
                        }}</router-link>
                      </li>
                      <li>
                        <router-link to="/digital-consulting">{{
                          $t("submenu_DigitalConsulting")
                        }}</router-link>
                      </li>
                      <li>
                        <router-link to="/digital-marketing">{{
                          $t("submenu_DigitalMarketing")
                        }}</router-link>
                      </li>
                      <li>
                        <router-link to="/business-strategy">{{
                          $t("submenu_BusinessStrategy")
                        }}</router-link>
                      </li>
                      <li>
                        <router-link to="/brand-development">{{
                          $t("submenu_BrandDevelopment")
                        }}</router-link>
                      </li>
                      <li>
                        <router-link to="/website-development">{{
                          $t("submenu_WebsiteDevelopment")
                        }}</router-link>
                      </li>
                      <li>
                        <router-link to="/design-uiux">{{
                          $t("submenu_DesignUIUX")
                        }}</router-link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <router-link to="">{{ $t("menu_about") }}</router-link>
                    <ul class="sub-menu">
                      <li>
                        <router-link to="/about">{{
                          $t("submenu_WhoAreWe")
                        }}</router-link>
                      </li>
                      <li>
                        <router-link to="/with-aipath">
                          {{ $t("submenu_WithAiPath") }}</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/why-aipath">
                          {{ $t("submenu_WhyAiPath") }}</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/team">{{
                          $t("submenu_Team")
                        }}</router-link>
                      </li>
                      <li>
                        <router-link to="/careers">{{
                          $t("submenu_Careers")
                        }}</router-link>
                      </li>
                      <li>
                        <router-link to="/contact">{{
                          $t("submenu_Contact")
                        }}</router-link>
                      </li>
                    </ul>
                  </li>

                  <!-- <li>
                    <router-link to="/about"><a>Pages</a></router-link>
                    <ul class="sub-menu">
                      <li>
                        <router-link to="/pricing">Pricing</router-link>
                      </li>
                      <li>
                        <router-link to="/portfolio">Portfolio</router-link>
                      </li>
                      <li>
                        <router-link to="/portfolio-details"
                          >Portfolio Details</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/team">Team</router-link>
                      </li>
                      <li>
                        <router-link to="/team-details"
                          >Team Details</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/faq">Faq</router-link>
                      </li>
                      <li>
                        <router-link to="/error-page">404</router-link>
                      </li>
                    </ul>
                  </li> -->
                  <li>
                    <router-link to="/blog">{{ $t("menu_news") }}</router-link>
                  </li>
                  <li>
                    <languageSwitchBootstrap />
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="col-xl-1 col-lg-1 col-6">
            <div
              class="header-right-info d-flex align-items-center justify-content-end"
            >
              <!-- <div class="header-search" @click="handleSearch">
                <button class="search-toggle" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div> -->
              <div class="sidebar__menu d-lg-none">
                <div
                  @click="menuOption.menuShow = !menuOption.menuShow"
                  class="sidebar-toggle-btn ml-30"
                  id="sidebar-toggle"
                >
                  <span class="line"></span>
                  <span class="line"></span>
                  <span class="line"></span>
                </div>
              </div>
              <div class="header-btn d-none d-lg-block">
                <router-link to="/project-idea" class="tp-btn">{{
                  $t("menu_getOffer")
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- search popup area start -->
  <div
    :class="`${
      searchPopup
        ? 'search__popup transition-3 opened'
        : 'search__popup transition-3'
    }`"
  >
    <div class="search__popup-close">
      <button type="button" class="search-popup-close-btn" @click="handleClose">
        <i class="fal fa-times"></i>
      </button>
    </div>
    <div class="search__popup-wrapper">
      <form action="#">
        <div class="search__popup-input">
          <input type="text" placeholder="Enter Your Keyword..." />
          <button type="submit"><i class="far fa-search"></i></button>
        </div>
      </form>
    </div>
  </div>
  <!-- search popup area end -->

  <!-- Mobile Menu Start -->
  <div class="fix">
    <div
      class="side-mobile-menu"
      :class="[menuOption.menuShow === true ? 'active' : '']"
    >
      <div class="sidebar__wrapper">
        <div
          @click="menuOption.menuShow = !menuOption.menuShow"
          class="sidebar__close"
        >
          <button class="sidebar__close-btn" id="sidebar__close-btn">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="sidebar__content">
          <div class="logo mb-40">
            <a href="index.html">
              <img
                src="../../assets/img/logo/LogoPg-smaller.webp"
                alt="Aipath Company Logo"
                style="width: 60px; height: 60px"
              />
              <h3>AiPath</h3>
            </a>
          </div>
          <div class="mobile-menu fix">
            <div class="side-info-content sidebar-menu mm-menu">
              <ul>
                <!-- <li
                  class="menu-item-has-children has-droupdown"
                  v-bind:class="[
                    menuOption.homeDropdown === true ? 'active' : '',
                  ]"
                >
                  <a
                    v-on:click="
                      menuOption.homeDropdown = !menuOption.homeDropdown
                    "
                    >Home</a
                  >
                  <ul
                    class="sub-menu"
                    v-bind:class="[
                      menuOption.homeDropdown === true ? 'active' : '',
                    ]"
                  >
                    <li>
                      <router-link to="/home">Home Style 1</router-link>
                    </li>
                    <li>
                      <router-link to="/home-two">Home Style 2</router-link>
                    </li>
                  </ul>
                </li> -->
                <li>
                  <router-link to="/">{{ $t("menu_Home") }}</router-link>
                </li>

                <li
                  class="menu-item-has-children has-droupdown"
                  v-bind:class="[
                    menuOption.serviceDropdown === true ? 'active' : '',
                  ]"
                >
                  <a @click="closeServices()">{{ $t("menu_services") }}</a>
                  <ul
                    class="sub-menu"
                    :class="[
                      menuOption.serviceDropdown === true ? 'active' : '',
                    ]"
                  >
                    <!-- new drop down -->
                    <li
                      class="menu-item-has-children haas-droupdown"
                      v-bind:class="[
                        menuOption.servicesDevelopmentDropdown === true
                          ? 'active'
                          : '',
                      ]"
                    >
                      <a
                        @click="
                          menuOption.servicesDevelopmentDropdown =
                            !menuOption.servicesDevelopmentDropdown
                        "
                        >{{ $t("submenu_title_Development") }}</a
                      >
                      <ul
                        class="suub-menu"
                        :class="[
                          menuOption.servicesDevelopmentDropdown === true
                            ? 'active'
                            : '',
                        ]"
                      >
                        <li>
                          <router-link to="/custom-apps">{{
                            $t("submenu_CustomDesktop")
                          }}</router-link>
                        </li>
                        <li>
                          <router-link to="/web-apps">{{
                            $t("submenu_WebApplications")
                          }}</router-link>
                        </li>
                        <li>
                          <router-link to="/mobile-apps">{{
                            $t("submenu_MobileApplications")
                          }}</router-link>
                        </li>
                        <li>
                          <router-link to="/ecommerce">{{
                            $t("submenu_ECommerce")
                          }}</router-link>
                        </li>
                        <li>
                          <router-link to="/api-dev">{{
                            $t("submenu_ApiDevelopment")
                          }}</router-link>
                        </li>
                      </ul>
                    </li>

                    <!-- <li>
                      <router-link to="/edu">Edu</router-link>
                    </li> -->
                    <li>
                      <router-link to="/machine-learning-solutions">{{
                        $t("submenu_MachineLearning")
                      }}</router-link>
                    </li>
                  </ul>
                </li>
                <li
                  class="menu-item-has-children has-droupdown"
                  v-bind:class="[
                    menuOption.servicesForStartUpsDropdown === true
                      ? 'active'
                      : '',
                  ]"
                >
                  <a
                    @click="
                      menuOption.servicesForStartUpsDropdown =
                        !menuOption.servicesForStartUpsDropdown
                    "
                    >{{ $t("menu_startups") }}</a
                  >
                  <ul
                    class="sub-menu"
                    :class="[
                      menuOption.servicesForStartUpsDropdown === true
                        ? 'active'
                        : '',
                    ]"
                  >
                    <li>
                      <router-link to="/cloud-solutions">{{
                        $t("submenu_CloudSolutions")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/digital-consulting">{{
                        $t("submenu_DigitalConsulting")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/digital-marketing">{{
                        $t("submenu_DigitalMarketing")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/business-strategy">{{
                        $t("submenu_BusinessStrategy")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/brand-development">{{
                        $t("submenu_BrandDevelopment")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/website-development">{{
                        $t("submenu_WebsiteDevelopment")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/design-uiux">{{
                        $t("submenu_DesignUIUX")
                      }}</router-link>
                    </li>
                  </ul>
                </li>
                <li
                  class="menu-item-has-children has-droupdown"
                  v-bind:class="[
                    menuOption.aboutDropdown === true ? 'active' : '',
                  ]"
                >
                  <a
                    @click="
                      menuOption.aboutDropdown = !menuOption.aboutDropdown
                    "
                    >{{ $t("menu_about") }}</a
                  >
                  <ul
                    class="sub-menu"
                    :class="[menuOption.aboutDropdown === true ? 'active' : '']"
                  >
                    <li>
                      <router-link to="/about">{{
                        $t("submenu_WhoAreWe")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/with-aipath">{{
                        $t("submenu_WithAiPath")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/why-aipath">{{
                        $t("submenu_WhyAiPath")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/team">{{
                        $t("submenu_Team")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/careers">{{
                        $t("submenu_Careers")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/contact">{{
                        $t("submenu_Contact")
                      }}</router-link>
                    </li>
                  </ul>
                </li>

                <li
                  class="menu-item-has-children has-droupdown"
                  v-bind:class="[
                    menuOption.languageDropdown === true ? 'active' : '',
                  ]"
                >
                  <a
                    @click="
                      menuOption.languageDropdown = !menuOption.languageDropdown
                    "
                    >{{ $t("menu_language") }}</a
                  >
                  <ul
                    class="sub-menu"
                    :class="[
                      menuOption.languageDropdown === true ? 'active' : '',
                    ]"
                  >
                    <li>
                      <a
                        @click="this.$i18n.locale = 'gr'"
                        class="d-flex align-items-center gap-2"
                      >
                        <img
                          style="width: 18px"
                          src="../../assets/icons/flags/greek.png"
                          alt="Greek Flag"
                        />{{ $t("menu_greek") }}</a
                      >
                    </li>
                    <li>
                      <a
                        @click="this.$i18n.locale = 'en'"
                        class="d-flex align-items-center gap-2"
                      >
                        <img
                          style="width: 18px"
                          src="../../assets/icons/flags/eng.png"
                          alt="English Flag"
                        />{{ $t("menu_english") }}</a
                      >
                    </li>
                  </ul>
                </li>

                <li>
                  <router-link to="/blog" class="border-0">{{
                    $t("menu_news")
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/project-idea" class="border-0">{{
                    $t("menu_getOffer")
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>

          <!-- <div class="sidebar__search p-relative mt-40 mb-20">
            <form action="#">
              <input type="text" placeholder="Search..." />
              <button type="submit"><i class="fad fa-search"></i></button>
            </form>
          </div> -->
          <div class="sidebar__contact mb-45">
            <ul>
              <li>
                <div class="icon theme-color">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="text theme-color">
                  <span
                    ><a href="mailto:info@aipath.gr">info@aipath.gr</a></span
                  >
                </div>
              </li>

              <li>
                <div class="icon theme-color">
                  <i class="fas fa-phone-volume"></i>
                </div>
                <div class="text theme-color">
                  <span><a href="tel:+30 211 3331954">+30 211 3331954</a></span>
                </div>
              </li>

              <li>
                <div class="icon">
                  <i class="fal fa-map-marker-alt"></i>
                </div>
                <div class="text">
                  <a
                    target="_blank"
                    href="https://www.google.com/maps/place/Dhaka/@23.7806207,90.3492859,12z/data=!3m1!4b1!4m5!3m4!1s0x3755b8b087026b81:0x8fa563bbdd5904c2!8m2!3d23.8104753!4d90.4119873"
                    >96 Dimitriou Gounari St. Marousi <br />
                    Athens, Greece.</a
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="body-overlay transition-3"
    :class="[menuOption.menuShow === true ? 'opened' : '']"
  ></div>
  <!-- Mobile Menu End -->
</template>

<script>
import AccordionMenu from "../../components/common/AccordionMenu.vue";
import languageSwitchBootstrap from "../common/languageSwitchBootstrap.vue";
export default {
  name: "HomeHeader",
  data() {
    return {
      isSticky: false,
      searchPopup: false,
      menuOption: {
        menuShow: false,
        menuSearch: false,
        homeDropdown: false,
        serviceDropdown: false,
        servicesDevelopmentDropdown: false,
        servicesForStartUpsDropdown: false,
        pagesDropDown: false,
        blogDropdown: false,
        aboutDropdown: false,
        languageDropdown: false,
      },
    };
  },
  components: {
    AccordionMenu,
    languageSwitchBootstrap,
  },
  methods: {
    closeServices() {
      this.menuOption.serviceDropdown = !this.menuOption.serviceDropdown;
      if (this.menuOption.serviceDropdown === false) {
        this.menuOption.servicesDevelopmentDropdown = false;
        this.menuOption.servicesForStartUpsDropdown = false;
      }
    },
    handleSticky() {
      if (window.scrollY > 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    handleSearch() {
      this.searchPopup = true;
    },
    handleClose() {
      this.searchPopup = false;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleSticky);
  },
};
</script>

<style scoped>
.half-line-height a {
  /* in mobile menu link text gets centered with line height, if 2 sentences it gets messed up */
  line-height: 23px !important;
}
@media screen and (width<1380px) {
  .header-btn,
  .tp-btn {
    display: flex;
    flex-direction: column;
    width: 140px;
    line-height: initial;
    font-size: 15px;
  }
  .header-title-custom {
    font-size: 35px;
  }
}
@media screen and (990px < width <1220px) {
  .main-menu ul li > a {
    font-size: 16px;
  }
  .tp-btn {
    font-size: 16px;
  }
  #header-sticky > div > div > div.col-xl-3.col-lg-3.col-6 > div > h1 {
    font-size: 30px;
  }
}
</style>
