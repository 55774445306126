<template>
  <router-view />
  <!-- <CookieBanner v-if="$route.path != '/contact-2'" @accept-cookies="hideCookieBanner" /> -->
  <CookieBanner v-if="$route.path != '/contact-2'" />

  <iframe
    v-if="$route.path != '/contact-2'"
    src="https://chatbot.aipath.gr/"
    id="chatIframer"
    title="ChatBot by AiPath"
  ></iframe>
</template>

<script>
/* eslint-disable */
import CookieBanner from "./components/common/CookieBanner.vue";
import "../src/assets/js/iframeSettings.js";
import "../src/assets/css/iframeStyling.css";
export default {
  name: "App",
  components: {
    CookieBanner,
  },
};
</script>
