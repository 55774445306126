<template>
  <div v-if="showBanner" class="cookie-banner">
    <div class="cookie-banner-content">
      <!-- Customize your cookie banner content -->
      <p>
        🍪 Cookie Notice 🍪 <br />
        We use cookies to enhance your experience on our website. By using this
        site, you consent to our use of cookies. Our cookies don't collect
        personal information and are used for analytics and advertising
        purposes.
      </p>
      <button @click="acceptCookies">Accept</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBanner: !this.$cookies.isKey("cookie_accepted"),
    };
  },
  methods: {
    acceptCookies() {
      // Check if the 'cookie_accepted' cookie is not set
      if (!this.$cookies.isKey("cookie_accepted")) {
        // Set the 'cookie_accepted' cookie with a value
        this.$cookies.set("cookie_accepted", "true", "365d"); // Expires in 365 days

        // Hide the banner when cookies are accepted
        this.showBanner = false;

        // Emit an event to notify the parent component
        // this.$emit("cookies-accepted");
      }
    },
  },
};
</script>

<style scoped>
/* Add CSS styles to customize your cookie banner */

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333; /* Background color of the banner */
  color: white; /* Text color */
  padding: 10px; /* Padding for the banner content */
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2); /* Optional box shadow */
  z-index: 1000001;
}
.cookie-banner p {
  color: white;
}

.cookie-banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
  gap: 1rem;
}

.cookie-banner p {
  margin: 0;
  text-align: justify;
}

.cookie-banner button {
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.cookie-banner button:hover {
  background-color: #0056b3; /* Hover state background color */
}
@media screen and (width<650px) {
  .cookie-banner-content {
    flex-direction: column;
    font-size: 12px;
  }
}
</style>
