window.onload = function () {
  // Set a session storage item indicating the chat is closed
  sessionStorage.setItem("chatClosed", "true");
  // Function to handle messages from the iframe
  function handleMessageFromIframe(event) {
    var iframe = document.getElementById("chatIframer");
    if (event.data.action === "openChat") {
      // Resize the iframe or perform any other action
      if (window.innerWidth < 600) {
        iframe.style.height = "100vh";
        iframe.style.height = "100svh";
        iframe.style.width = "100%";
      } else {
        //Check if website in mobile landscape mode
        if (window.innerHeight < 698) {
          iframe.style.height = "100%";
        } else {
          iframe.style.height = "698px";
        }
        iframe.style.width = "600px";
      }
      // Set a session storage item indicating the chat is closed
      sessionStorage.setItem("chatClosed", "false");
    }
    if (event.data.action === "closeChat") {
      setTimeout(() => {
        iframe.style.height = "120px";
        iframe.style.width = "120px";

        // Set a session storage item indicating the chat is closed
        sessionStorage.setItem("chatClosed", "true");
      }, 500);
    }
  }
  // Add an event listener for messages from the iframe
  window.addEventListener("message", handleMessageFromIframe);

  // // Add an event listener for window resize
  window.addEventListener("resize", function () {
    var iframe = document.getElementById("chatIframer");
    var isChatClosed = sessionStorage.getItem("chatClosed");
    if (window.innerWidth < 600) {
      if (isChatClosed == "true") {
        iframe.style.height = "120px";
        iframe.style.width = "120px";
      } else {
        iframe.style.height = "100svh";
        iframe.style.width = "100%";
      }
    } else {
      if (isChatClosed == "true") {
        iframe.style.height = "120px";
        iframe.style.width = "120px";
      } else {
        //Check if website in mobile landscape mode
        if (window.innerHeight < 698) {
          iframe.style.height = "100%";
        } else {
          iframe.style.height = "698px";
        }
        iframe.style.width = "600px";
      }
    }
  });
};
