import axios from 'axios';

export default{
    // getBlogPosts(){
    //     const BLOG_SITE_URL = 'https://www.devsclub.gr'
    //     const LIMIT_PAGES = '?per_page=';
    //     const NUMBER_OF_POSTS = '5';
    //     const LIMIT_ATTRIBUTES = '&fields=';
    //     const ATTRIBUTE_OPTIONS = 'id,title,excerpt,date,author,,featured_media,content';
    //     return axios.get(BLOG_SITE_URL+'/wp-json/wp/v2/posts'+LIMIT_PAGES+NUMBER_OF_POSTS+LIMIT_ATTRIBUTES+ATTRIBUTE_OPTIONS)
    // }
    getBlogPosts(){
        return axios.get('https://backwp.aipath.gr/wp-json/aipath/v1/get-blog-posts');
    }
}