import Vuex from "vuex";
import GetBlogPosts from "./services/getBlogPosts";
import getCareers from "./services/getCareers";
// import GetBlogAuthors from './services/getBlogAuthors';

const storedPosts = localStorage.getItem("simplifiedPosts");
const storedCareers = localStorage.getItem("careersData");

export default new Vuex.Store({
  state: {
    simplifiedPosts: storedPosts ? JSON.parse(storedPosts) : [], // Initialize with data from localStorage
    currentPage: 1, // Current page number
    postsPerPage: 2, // Number of posts per page
    totalPosts: 0, // Total number of posts !!! change in services/getposts too
    totalPages: 0, // Total number of pages
    maxVisiblePages: 3, // Maximum number of visible page numbers in pagination control
    careers: storedCareers ? JSON.parse(storedCareers):[], //Career entries
  },
  mutations: {
    // Define mutations to update the state
    setSimplifiedPosts(state, posts) {
      state.simplifiedPosts = posts;
      // Update the total number of pages
      state.totalPages = Math.ceil(posts.length / state.postsPerPage);
      // Save posts to localStorage
      localStorage.setItem("simplifiedPosts", JSON.stringify(posts));
    },
    setCareers(state, careersData) {
      state.careers = careersData;
      localStorage.setItem('careersData', JSON.stringify(careersData));
    },
    incrementPage(state) {
      if (state.currentPage < state.totalPages) {
        state.currentPage++;
      }
    },
    decrementPage(state) {
      if (state.currentPage > 1) {
        state.currentPage--;
      }
    },
    setCurrentPage(state, page) {
      if (page >= 1 && page <= state.totalPages) {
        state.currentPage = page;
      }
    },
  },
  actions: {
    async fetchAndSaveSimplifiedPosts({ commit }) {
      try {
        // Fetch posts and users here (as shown in step 1)
        const blogResponse = await GetBlogPosts.getBlogPosts();
        const blogData = blogResponse.data;

        this.state.totalPosts = blogData.length;
        // Create an array to store the simplified objects
        const simplifiedPosts = [];

        // Iterate through the original data
        blogData.forEach((item) => {
          // Extract the properties you need
          const simplifiedItem = {
            entry_id: item.entry_id,
            date_created: item.date_created,
            post_title: item.meta_data["postdata-1"]['value']['value']["post-title"],
            post_content: item.meta_data["postdata-1"]['value']['value']["post-content"],
            post_image:item.meta_data["postdata-1"]['value']['value']["post-image"].uploaded_file[0],
          };

          // Push the simplified object to the array
          simplifiedPosts.push(simplifiedItem);
        });

        // Commit the simplified posts to the store
        commit("setSimplifiedPosts", simplifiedPosts);
      } catch (error) {
        console.error("Error fetching and saving simplified posts:", error);
      }
    },
     // Action to fetch data from the API and commit the mutation
     async fetchCareers({ commit }) {
      try {
        // Make an API request to get careers data
        const response = await getCareers.getCareers();
        
        // Extract the relevant data from the API response
        const careersData = response.data;

        const simplifiedCareers = [];

        // Iterate through the original data
        careersData.forEach((item) => {
          // Extract the properties you need
          const simplifiedItem = {
            entry_id: item.entry_id,
            date_created: item.date_created,
            career_title: item.meta_data["text-1"]['value'],
            career_seniority:item.meta_data["select-3"]['value'],
            career_location:item.meta_data["select-2"]['value'],
            career_type:item.meta_data["select-1"]['value'],
            career_short_desc: item.meta_data["textarea-1"]['value'],
            career_desc:item.meta_data["textarea-2"]['value'],
          };
              // Push the simplified object to the array
              simplifiedCareers.push(simplifiedItem);
        })

        // Commit the mutation to update the state with the fetched data
        commit('setCareers', simplifiedCareers);
      } catch (error) {
        // Handle any errors, e.g., log them or show a user-friendly message
        console.error('Error fetching careers:', error);
      }
    },
  },
  getters: {
     // Getter to access the careers data in components
     getCareersData: (state) => {
      return state.careers;
    },
    getCareerById: (state) => (id) => {
      // Use the Array.find() method to search for the career by its ID
      return state.careers.find(career => career.entry_id === id);
    },
    // Define getters to access the data
    getSimplifiedPosts: (state) => {
      // Retrieve posts from localStorage if available
      const storedPosts = localStorage.getItem("simplifiedPosts");
      if (storedPosts) {
        return JSON.parse(storedPosts);
      } else {
        return state.simplifiedPosts;
      }
    },
    paginatedBlogEntries: (state) => {
      const startIndex = (state.currentPage - 1) * state.postsPerPage;
      const endIndex = startIndex + state.postsPerPage;
      return state.simplifiedPosts.slice(startIndex, endIndex);
    },
    visiblePageNumbers: (state) => {
      const halfMaxVisiblePages = Math.floor(state.maxVisiblePages / 2);
      const totalPages = state.totalPages;

      let startPage = Math.max(state.currentPage - halfMaxVisiblePages, 1);
      let endPage = Math.min(startPage + state.maxVisiblePages - 1, totalPages);

      if (endPage - startPage < state.maxVisiblePages - 1) {
        startPage = Math.max(endPage - state.maxVisiblePages + 1, 1);
      }
      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },
    getBlogById: (state) => (id) => {
      return state.simplifiedPosts.find((post) => post.entry_id.toString() === id);
    },
    getThreeBlogs: (state) => {
      return state.simplifiedPosts.slice(0, 3);
    },
  },
});
