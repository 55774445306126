<template>
  <Header />
  <HeroSlider />
  <CardServices />
  <Services />
  <VideoDisplay :useAlternativeTemplate="true" />
  <!-- <Counter/> -->
  <Faq />
  <About />
  <!-- <Portfolio/> -->
  <Cta />
  <Blog />
  <!-- <Team /> -->
  <Footer />
</template>

<script>
import Header from "../../components/Home/Header.vue";
import HeroSlider from "../../components/Home/HeroSlider.vue";
import Services from "../../components/Home/Services.vue";
import VideoDisplay from "../../components/common/VideoDisplay.vue";
import About from "../../components/Home/About.vue";
// import Counter from '../../components/Home/Counter.vue';
import CardServices from "@/components/Home/CardServices.vue";
// import Team from "../../components/Home/Team.vue";
import Faq from "../../components/Home/Faq.vue";
// import Portfolio from '../../components/Home/Portfolio.vue';
import Cta from "../../components/Home/Cta.vue";
import Blog from "../../components/Home/Blog.vue";
import Footer from "../../components/Home/Footer.vue";

import { mapActions } from "vuex";

export default {
  name: "HomePage",
  components: {
    Header,
    HeroSlider,
    CardServices,
    Services,
    VideoDisplay,
    About,
    // Counter,
    // Team,
    Faq,
    // Portfolio,
    Cta,
    Blog,
    Footer,
  },
  mounted() {
    // Call the Vuex store action to fetch and save simplified posts
    this.fetchAndSaveSimplifiedPosts();
  },
  methods: {
    ...mapActions(["fetchAndSaveSimplifiedPosts"]),
  },
};
</script>
<style>
h1,
h2,
h3,
h4,
h5 {
  text-transform: none !important;
}
</style>
