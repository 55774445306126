import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueEasyLightbox from "vue-easy-lightbox";
import VueGtag from "vue-gtag";
import VueCookies from "vue-cookies";
// import BootstrapVue3 from "bootstrap-vue-3";
import PrimeVue from "primevue/config";
import i18n from "./assets/i18n/i18n";
// import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.es5.esm.min.js';
import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/js/bootstrap.bundle";
import "swiper/css/bundle";
import "./assets/css/fontAwesome5Pro.css";
import "./assets/fonts/flaticon.css";
// import "bootstrap";
// import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "./assets/scss/main.scss";
// import "bootstrap/dist/js/bootstrap.bundle";
import store from "./store";

let app = createApp(App);
app.use(store);
app.use(router);
app.use(PrimeVue);
app.use(i18n);
app.use(VueCookies);
app.use(
  VueGtag,
  {
    appName: "AiPath Website",
    pageTrackerScreenviewEnabled: true,
    config: { id: "G-NY6FNTNBES" },
    // config: { id: "G-Y7FMD6WSG0" },
  },
  router
);
// Ads tracker
app.use(
  VueGtag,
  {
    appName: "AiPath Ads",
    pageTrackerScreenviewEnabled: true,
    config: { id: "G-Y7FMD6WSG0" },
    bootstrap: false, // Do not automatically bootstrap the tracker (handled by the regular analytics tracker)
  },
  router
);
// app.use(BootstrapVue3);
app.use(VueEasyLightbox);
app.mount("#app");
