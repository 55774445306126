<template>
  <div
    class="faq-area black-bg pt-120 pb-90"
    :style="{
      backgroundImage:
        'url(' + require('../../assets/img/pattern/dot.png') + ')',
    }"
    data-background="assets/img/pattern/dot.png"
  >
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6">
          <div class="sec-wrapper mb-30">
            <!-- <h5>Συχνες Ερωτησεις</h5> -->
            <h2 class="section-title text-white">Σε τι διαφέρουμε?</h2>
            <p>
              Στην AiPath, επιλέγουμε τo μοντέλο Agile Development για γρήγορη
              και ευέλικτη προσαρμογή. Εξειδικευόμαστε σε Custom Desktop
              Applications, εντασσοντας τη δύναμη του Machine Learning σε
              καθημερινή χρήση. Όλα αυτά, με τιμές που αντανακλούν την
              πραγματικότητα της αγοράς.
            </p>
            <div class="ab-btn mt-30">
              <router-link to="/about" class="tp-btn">Learn More</router-link>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Agile Development
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    Στην AiPath, υιοθετούμε την προσέγγιση Agile, εντάσσοντας
                    τον πελάτη ενεργά στη διαδικασία ανάπτυξης. Με αυτόν τον
                    τρόπο, επιταχύνουμε την παράδοση, ενώ εστιάζουμε στη
                    συνεργασία και στις πραγματικές ανάγκες των πελατών μας.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Custom Desktop Applications
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    Στην AiPath, ειδικευόμαστε σε προσαρμοσμένες εφαρμογές για
                    desktop, που ανταποκρίνονται ακριβώς στις επιχειρηματικές
                    ανάγκες. Κατανοώντας βαθιά τις τεχνολογικές προκλήσεις,
                    προσφέρουμε λύσεις που βελτιώνουν την παραγωγικότητα και τη
                    διαχείριση της επιχείρησης.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Machine Learning
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    Στην AiPath, πιστεύουμε ότι το Machine Learning δεν είναι
                    απλά ένα εργαλείο, αλλά ένα ολόκληρο οικοδόμημα που μπορεί
                    να μετασχηματίσει τον τρόπο λειτουργίας μιας επιχείρησης.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree1">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree1"
                  aria-expanded="false"
                  aria-controls="collapseThree1"
                >
                  Τιμες εντος πραγματικότητας!
                </button>
              </h2>
              <div
                id="collapseThree1"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree1"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    Στην AiPath, προσφέρουμε προσιτές και προσαρμοσιμες
                    τεχνολογικές λύσεις. Δεν αφήνουμε τον προϋπολογισμό να είναι
                    εμπόδιο. Προσαρμόζουμε τις υπηρεσίες μας στις ανάγκες κάθε
                    επιχείρησης, εξασφαλίζοντας την καλύτερη τεχνολογική
                    υποστήριξη.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeFaq",
};
</script>
